import {differenceInCalendarDays,isValid,parseISO} from "date-fns"

export const isEmailValid = (val)=>{
    return val && val.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ? true
    : false
}

export const isURLValid = (val)=>{
    return val && val.match(/^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/(?!\/)[\w-.?%&=/]*)?$/)
    ? true
    : false
}

export const isValidEIN = (val)=> {
    return val && val.match(/^\d{2}-\d{7}$/)
    ? true
    : false
}

export const isValidDuns =  (val) => {
    return val && val.match(/^\d{9}$/)
    ? true
    : false
}

export const formatCurrency = (val, minLimit = 0, maxLimit = 50000000, allowDecimals = false) => {
        if(!val) {return (0).toFixed(allowDecimals ? 2 : 0);}
    // Remove any characters that are not digits or decimal points
        let value = val.replace(/[^\d.]/g, '');

        // Convert the value to a number. If it's not a number, set it to the minimum limit
        let number = value ? parseFloat(value) : minLimit;

        // Round the number to two decimal places if allowDecimals is true
        number = allowDecimals ? Math.round(number * 100) / 100 : Math.round(number);

        // Check for minimum and maximum limits
        if (number < minLimit) {
            number = minLimit;
        } else if (number > maxLimit) {
            number = maxLimit;
        }

        // Format the number with commas and optionally with two decimal places
        return allowDecimals ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatUSCurrency = (val,max) => {
        let input = val.replace(/[^\d.]+/g, '')
        if(max && Number(input) > max) {input = `${max}`}
        const parts = input.split('.')
        const dollars = parts[0] ? parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): parts[0]
        const cents = parts[1] ? parts[1].length > 2 ? parts[1].slice(0,2) : parts[1] : parts[1]
        return input.includes('.') ? `${dollars}.${cents}` : `${dollars}`
}

export const formatPercentage = (val,max=100)=> {
    let input = val.replace(/[^\d.]+/g, '')
    if(Number(input) > max) {input = `${max}`}
    if(Number(input) < 0) {input = `0`}
    const parts = input.split('.')
    const pct = parts[0]
    const dec = parts[1] ? parts[1].length > 2 ? parts[1].slice(0,2) : parts[1] : parts[1]
    return input.includes('.') ? `${pct}.${dec}` : `${pct}`
}

export const formatNumberInput = (val,min=0,max=100)=> {
    if(!val) { return `${min}` }
    let input = val.replace(/[^\d]+/g, '')
    if(Number(input) > max) {input=`${max}`}
    if(Number(input) < min) {input=`${min}`}
    input = Math.round(Number(input))
    return `${input}`
}

export const formatInputNumber = (val,min=0,max=100)=>{
    if(!val) {return null}
    let input = val.replace(/[^\d]+/g, '')
    input = Math.round(Number(input))
    if(Number(input) > max) {input=`${max}`}
    if(Number(input) < min) {input=`${min}`}
    return input
}

export const formatEIN = (val)=>{
         // Remove any characters that are not digits
         let input = val.replace(/[^\d]/g, '');

         // Automatically add hyphen after second digit
         if (input.length > 2) {
           input = input.slice(0, 2) + '-' + input.slice(2);
         }
   
         // Limit the length to 10 characters (XX-XXXXXXX)
         return input.slice(0, 10);
}

export const formatDuns = (val) => {

    // Remove any characters that are not digits
    let input = val.replace(/[^\d]/g, '');

    // Limit the length to 9 characters
    if (input.length > 9) {
      input = input.slice(0, 9);
    }

    // Update the DUNS number
    return input
}

export const showAsUSCurrency = (val)=>{
    const formattedString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(val);
    return formattedString.replace('$', '$ ')
}

export const formatUSPhoneNumber = (val)=>{
    if(val) {
        const phoneNumber = val.target?.value?.replace(/\D/g, '').slice(0,10)
        const maskedPhoneNumber = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return maskedPhoneNumber
    } 
}

export const displayUSPhone = (val)=>{
    if(val) {
        const phoneNumber = val.replace(/\D/g, '').slice(0,10)
        const maskedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        return maskedPhoneNumber
    }
}

export const getAge = (date)=>{
    if(!date || !isValid(new Date(date))) {
        return 0
    } else {
        let compareDate = new Date(date)
        return differenceInCalendarDays(new Date(),compareDate)
    }
    
}

export const openDetails = (target)=> {
    if(target) {
        const nextElement = target.nextElementSibling;
        nextElement
        ? nextElement.classList.toggle('hidden')
        : null
    } else {
        console.log('No target')
    }
}

export const copyToClipboard = async (val)=>{
    try {
        await navigator.clipboard.writeText(val);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

export const decodeHtml = (html) => {
    if(!html) {return ''}
    const htmlEntitiesMap = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": "\"",
        "&#039;": "'",
        // Add more entities here if needed
    };

    return html.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, match => htmlEntitiesMap[match]);
}

export const dateInputToISO = (val)=>{
    if(val && isValid(new Date(val))) {
        const selectedDate = new Date(val)
        const convertedDate = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() *60000)
        return convertedDate
    } else {
        return null
    }
}

export const dateTimeInputToISO = (val)=>{
    if(val && isValid(new Date(val))) {
        const selectedDate = parseISO(val)
        const convertedDate = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() *60000)
        return convertedDate
    } else {
        return null
    }
}

export const formatISODateforInput = (val)=>{
    if (val && isValid(new Date(val))) {
        const newDate = parseISO(val)
        const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(newDate.getUTCDate()).padStart(2, '0');
        return `${newDate.getUTCFullYear()}-${month}-${day}`;
    } else {
        return null
    }
}

export const formatISODateforDateTimeInput = (val)=>{
    if (val && isValid(new Date(val))) {
        const selectedDate = new Date(val); // Create a new Date object from your input value
        const year = selectedDate.getFullYear(); // Get the local year
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Get the local month, pad with zero
        const day = String(selectedDate.getDate()).padStart(2, '0'); // Get the local day, pad with zero
        const hours = String(selectedDate.getHours()).padStart(2, '0'); // Get local hours, pad with zero
        const minutes = String(selectedDate.getMinutes()).padStart(2, '0'); // Get local minutes, pad with zero

        // Return the date formatted as 'YYYY-MM-DDTHH:mm', suitable for datetime-local input
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    } else {
        return null;
    }
}

export const groupItemsByManufacturer = (items) =>{
    const sortedItems = items.sort((a, b) => {
        const manufacturerCompare = a.manufacturer.localeCompare(b.manufacturer);
        if (manufacturerCompare !== 0) return manufacturerCompare;
        return a.boxNumber - b.boxNumber; // Assuming boxNumber is numerical
    });

    const manufacturers = [];

    sortedItems.forEach(item => {
        let manufacturer = manufacturers.find(m => m.name === item.manufacturer);
        if (!manufacturer) {
        manufacturer = { name: item.manufacturer, items: [] };
        manufacturers.push(manufacturer);
        }
        manufacturer.items.push(item);
    });
    return manufacturers;
}

export const getItemsByManufacturer = (equipment,type,groupMulti=true)=>{
    if(!Array.isArray(equipment)) {return equipment}
    let itemsToProcess = []

    switch(type) {
        case 'equipment':
            itemsToProcess = equipment.filter(item => !item.kitId)
            break;
        case 'kits':
            itemsToProcess = equipment.filter(item => item.kitId);
            break;
        case 'accessories':
            itemsToProcess = equipment.filter(item => !item.kitId);
            break;
        default:
            itemsToProcess = equipment
            break;
    }

    const groupedByManufacturer =  groupItemsByManufacturer(itemsToProcess)

    if(type === 'kits') {
        groupedByManufacturer.forEach(manufacturer => {
            const kitsWithItems = []; // Will hold kits with their related items nested

            // First, find all parent kits
            const parentKits = manufacturer.items.filter(item => item.isKit);

            parentKits.forEach(kit => {
            // Initialize the kit object with an empty 'items' array for its related items
            const kitWithRelatedItems = {
                ...kit,
                items: []
            };

            // Find and add related items (!isKit but matching kitId) to the parent kit
            manufacturer.items.forEach(item => {
                if (!item.isKit && item.kitId === kit.kitId) {
                kitWithRelatedItems.items.push(item);
                }
            });

            // Add the parent kit (with its related items nested) to the kitsWithItems array
            kitsWithItems.push(kitWithRelatedItems);
            });

            // Replace the manufacturer's items with the newly grouped kits and their items
            manufacturer.items = kitsWithItems;
        })
    } else {
        if(groupMulti) {

        groupedByManufacturer.forEach(manufacturer => {
            const newItems = []; // Will hold both individual items and grouped multi-box items
            const boxNumberGroups = {}; // Temporary storage for multi-box items

            manufacturer.items.forEach(item => {
                if (item.multiBox) {
                    // For items with multiBox: true, group by boxNumber
                    if (!boxNumberGroups[item.boxNumber]) {
                    boxNumberGroups[item.boxNumber] = {
                        boxNumber: item.boxNumber,
                        items: [],
                        multiBox: true
                    };
                    }
                    boxNumberGroups[item.boxNumber].items.push(item);
                } else {
                    // Items without multiBox: true remain individual
                    newItems.push(item);
                }
            });
            // Add grouped multi-box items to the newItems array
            Object.values(boxNumberGroups).forEach(group => newItems.push(group));

            // Replace the manufacturer's items with the new array
            manufacturer.items = newItems;
        });
        }
    } 
    return groupedByManufacturer
}

export const groupServices = (services,contract=true)=>{

    let contractServices = [...services].filter(x => contract ? x.document === 'contract' : x.document !== 'contract')
        
    contractServices.sort((a, b) => {
        if (a.document !== b.document) {
            return a.document.localeCompare(b.document);
        } else {
            return a.displayOrder - b.displayOrder;
        }
    });

    let groupData = {};

    // Group services while updating summaries at each level.
    contractServices.forEach(item => {
        let document = item.document || 'unidentified';
        if (!groupData[document]) {
            groupData[document] = {summary: {hours: 0, revenue: 0}, categories: {}};
        }

        let category = item.category;
        if (!groupData[document].categories[category]) {
            groupData[document].categories[category] = {summary: {hours: 0, revenue: 0}, services: {}};
        }

        // Update document-level summary.
        groupData[document].summary.hours += item.hours || 0;
        groupData[document].summary.revenue += item.revenue || 0;

        // Update category-level summary.
        groupData[document].categories[category].summary.hours += item.hours || 0;
        groupData[document].categories[category].summary.revenue += item.revenue || 0;

        let partNumber = item.partNumber;
        if (!groupData[document].categories[category].services[partNumber]) {
            groupData[document].categories[category].services[partNumber] = {summary: {hours: 0, revenue: 0}, items: []};
        }

        // Update part number-level summary.
        groupData[document].categories[category].services[partNumber].summary.hours += item.hours || 0;
        groupData[document].categories[category].services[partNumber].summary.revenue += item.revenue || 0;
        groupData[document].categories[category].services[partNumber].items.push(item);
    });

    return groupData
}

export const distanceBetweenPoints = (lon1,lat1,lon2,lat2,unit='M')=> {
    // Validate input parameters
    if (typeof lon1 !== 'number' || typeof lat1 !== 'number' ||
        typeof lon2 !== 'number' || typeof lat2 !== 'number' ||
        !['K', 'N', 'M'].includes(unit)) {
        console.error('Invalid input parameters to calculate distance.');
        return 0; // Indicate an error with the input.
    }

    // Check if the coordinates are the same, which would mean a distance of 0.
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    }

    // Convert latitude and longitude from degrees to radians.
    var radLat1 = Math.PI * lat1 / 180;
    var radLat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radTheta = Math.PI * theta / 180;

    // Calculate the distance using the haversine formula.
    var dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = Math.min(dist, 1); // Ensure dist does not exceed 1 due to rounding errors.
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515; // Distance in miles.

    // Convert the distance to the requested unit.
    if (unit === "K") {
        dist *= 1.609344; // Convert to kilometers.
    } else if (unit === "N") {
        dist *= 0.8684; // Convert to nautical miles.
    }
    // No conversion needed if the unit is miles (M), as dist is already in miles.

    return parseFloat(dist.toFixed(1));
}

export const capitalizeFirstLetter = (str)=>{
    if(str && typeof str === 'string') {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return str
}

export const naturalSort = (array, key1, key2 = null) => {
    const compareNatural = (a, b) => {
        // Handle undefined or null values
        if (a == null) a = '';
        if (b == null) b = '';

        // Convert to string and use localeCompare with numeric option
        return a.toString().localeCompare(b.toString(), undefined, { numeric: true });
    };

    return array.sort((a, b) => {
        // Extract values from objects
        let val1a = a[key1];
        let val1b = b[key1];

        // Natural sort comparison for the first key
        const cmp1 = compareNatural(val1a, val1b);

        if (cmp1 !== 0 || !key2) {
            return cmp1;
        }

        // Extract values for the second key if present
        let val2a = a[key2];
        let val2b = b[key2];

        // Natural sort comparison for the second key
        return compareNatural(val2a, val2b);
    });
};

export const sanitizeFileName = (name) => {
    return name.replace(/[/\\?%*:|"<>]/g, '-'); // Replace problematic characters with hyphens
};

export const camelCaseToLabel = (name)=>{
    if(!name) {return null}
    if(name === 'pto') {return 'PTO'}
    return name
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each capital letter
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
    .replace(/\b\w/g, char => char.toUpperCase());
}

export const filterKeys = (object, keepZero = false) => {
    if (typeof object !== 'object' || object === null) return {};

    return Object.fromEntries(
        Object.entries(object).filter(([key, value]) => {
            if (key && typeof value === 'number') {
                return keepZero || value !== 0; // Keep zeros if 'keepZero' is true
            }
            return false; // Only keep numbers
        })
    );
};

const getWebGLInfo = () => {
    try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (!gl) return 'unknown';

        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        const vendor = debugInfo ? gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) : 'unknown';
        const renderer = debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : 'unknown';

        return `${vendor.toLowerCase()}~${renderer.toLowerCase()}`.trim();
    } catch (err) {
        console.error('Error fetching WebGL info:', err);
        return 'unknown';
    }
};

export const generateDeviceFingerprint = async () => {
    try {
        const components = [
            (navigator?.userAgent || 'unknown').toLowerCase().trim(),
            (navigator?.language || 'unknown').toLowerCase().trim(),
            (Intl?.DateTimeFormat?.().resolvedOptions?.().timeZone || 'unknown').toLowerCase().trim(),
            //`${window?.screen?.width || 'unknown'}x${window?.screen?.height || 'unknown'}`,
            navigator?.deviceMemory || 'unknown',
            (navigator.userAgentData?.platform || 'unknown').toLowerCase().trim(),
            navigator?.hardwareConcurrency || 'unknown',
            navigator?.maxTouchPoints ?? 'unknown',
            getWebGLInfo()
        ];

        // Check if all components are 'unknown'
        const allUnknown = components.every(component => component === 'unknown');
        if (allUnknown) {
            console.warn('All fingerprint components are unknown.');
            return null;
        }

        const rawFingerprint = components.join('|');

        // Check if the Crypto API is supported
        if (!crypto?.subtle?.digest) {
            console.error('Crypto API is not supported in this environment.');
            return null;
        }

        // Hash the fingerprint using SHA-256
        const encoder = new TextEncoder();
        const data = encoder.encode(rawFingerprint);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);

        // Convert the hash to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const fingerprint = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        return fingerprint;
    } catch (error) {
        console.error('Error generating device fingerprint:', error);
        return null;
    }
};