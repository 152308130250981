import { reactive, readonly, computed, toRaw} from 'vue'
//import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { socketConnect } from './components/socketConnection'
import {format} from 'date-fns'
import api from './api'
import CryptoJS from 'crypto-js';
const broadcastEncryptionKey = 'digitallaborsolutionsIsEncryptingData'
const broadcastOriginId = 'dlstracker-8675309'

const encrypt = (data)=>{ 
  return CryptoJS.AES.encrypt(JSON.stringify(data), broadcastEncryptionKey ).toString();
}

const decrypt = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, broadcastEncryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

document.addEventListener('visibilitychange',()=>{
  if(document.visibilityState === 'visible') {
    console.log('App is active. Checking token status...')
    checkTokenExpiry()
  }
})


// State
  const state = reactive({
    auth: {
      loggedIn: false,
      name: '',
      email: '',
      role: {},
      token: '',
      refreshToken:'',
      organization: '',
      timesheet:false,
      onSchedule:false,
      fourDayWeek:false
    },
    systemStatus: {
      dbConnection: false,
      wsConnection: false
    },
    logoutError: false,
    resetPassword: false,
    sideBarOpen: false,
    logOutFlag: false,
    modalBlock:false,
    darkModeState:true,
    scrollBarState:false,
    projectListSettings:{
      projectNumberFilter:'',
      sortBy:null,
      sortDirection:false,
      userIsAssigned:false,
      showRackOnly:false,
      scrollTop:0,
      hideAwaitingInvoiceClose:true
    },
    preReleaseAuthState:{},
    isAuthReceived:false
  })

// Getters
  const authenticated = computed(() => state.auth)
  const appStatus = computed(() => state.systemStatus)
  const darkState = computed(()=>state.darkModeState)
  const scrollState = computed(()=>state.scrollBarState)
  const isLogoutError = computed(() => state.logoutError)
  const resetPasswordFlag = computed(() => state.resetPassword)
  const sideBar = computed(() => state.sideBarOpen)
  const initials = computed(() => {
    if (state.auth.name.indexOf(" ") > 0) {
      let nameArray = state.auth.name.split(" ")
      let nameArraylength = nameArray.length
      return nameArray[0].charAt(0) + nameArray[nameArraylength - 1].charAt(0)
    } else return state.auth.name.charAt(0)
  })
  const loggingOut = computed(() => state.logOutFlag)
  const expiration = computed(() => {
    if (state.auth.token) {
      const decodedToken = jwt_decode(state.auth.token)
      return decodedToken.exp
    } else return ''
  })
  const isModalBlocked = computed(()=>state.modalBlock)
  const projectViewSettings = computed(()=>state.projectListSettings)
  const preReleaseAuth = computed(()=>
    authenticated?.value?._id
    ? Object.keys(state.preReleaseAuthState)
    .filter(key => {
      const value = state.preReleaseAuthState[key];
      return (
        Array.isArray(value.authorizedIds) &&
        value.authorizedIds.includes(authenticated.value._id) &&
        (value.primaryOnly ? authenticated.value.organization?.orgType?.includes('primary') : true)
      );
    }).map((key) => key)
    : []
  )
  const authRecieved = computed(()=>state.isAuthReceived)

//Auth Broadcast Channel
  const authChannel = new BroadcastChannel('auth-channel')

  authChannel.onmessage = (event)=>{
    if(event.data?.type === 'auth_request') {
      console.log('Received auth request.')
      if(authenticated.value.loggedIn) {
        console.log('Sending Authentication to Requestor.')
        const encryptedAuth = encrypt({
          ...toRaw(authenticated.value),
          timestamp: Date.now(),
          originId:broadcastOriginId
        });
        authChannel.postMessage({type:'auth',auth:encryptedAuth})
      }
    } else if (event.data?.type === 'auth') {
      try {
        if(!authenticated.value.loggedIn && !authRecieved.value && !window.location?.pathname?.includes('/external')) {
          
            const decryptedAuth = decrypt(event.data.auth);
            if(decryptedAuth?.originId === broadcastOriginId && Date.now() - decryptedAuth.timestamp < 5000) {
              setAuthReceived(true)
              login(decryptedAuth);
            }
        }
      } catch (error) {
        console.error('Failed to decrypt auth data:', error);
      }
    } else if (event.data?.type === 'other-tab-login' && window.location.pathname === '/login') {
      if(!authenticated.value.loggedIn && !authRecieved.value) {
        window.location.reload()
      }
    } else if (event.data?.type === 'logout' && window.location.pathname !== '/login' && !window.location?.pathname?.includes('/external')) {
      window.location.reload()
    }
  }

  const requestAuth = ()=>{
    return new Promise((resolve) => {
    console.log('Requesting Authentication.')
    setTimeout(() => {
      resolve(null)
    }, 300);
    authChannel.postMessage({type:'auth_request'})
    })
  }

  const checkTokenExpiry = ()=>{
    const token = authenticated.value?.token
    if (!token) {
      console.warn('No token found. User might not be logged in.');
      return;
    }

    const tokenPayload = parseJwt(token)

    if (!tokenPayload || !tokenPayload.exp) {
      console.error('Invalid token or missing expiration.');
      return;
    }

    const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds
    const now = Date.now();

    if (now >= expirationTime) {
        console.warn('Token has expired. Logging out...');
        logout(); // Handle token expiration (e.g., redirect to login)
    } else if (now >= expirationTime - 60000) { // 1 minute before expiration
        console.log('Token is about to expire. Refreshing...');
        startTokenRefresh(); // Refresh the token
    } else {
        console.log('Token is valid. No action needed.');
    }
  }

// Mutations
  const setAuthReceived = (value)=>{
    state.auth.isAuthReceived = value
  }

  const setTheme = (value) =>{
    value === 'dark'
    ? state.darkModeState = true
    : state.darkModeState = false
  }

  const setThemefromProfile = (value)=>{
    value === 'dark'
    ? state.darkModeState = true
    : state.darkModeState = false

    localStorage.setItem('theme', state.darkModeState ? 'dark' : 'light')
  }

  const toggleTheme = ()=>{
    state.darkModeState = !state.darkModeState
    localStorage.setItem('theme', state.darkModeState ? 'dark' : 'light')
    persistThemeSettings({'darkModeState':state.darkModeState ? 'dark' : 'light' })
  }

  const setScrollBars = (value)=>{
    value === 'true' || value === true
    ? state.scrollBarState = true
    : state.scrollBarState = false
  }

  const setScrollBarsfromProfile = (value)=>{
    value ? state.scrollBarState = true : state.scrollBarState = false
    localStorage.setItem('scrollBars', state.scrollBarState ? 'true' : 'false')
  }

  const toggleScrollBars = ()=>{
    state.scrollBarState = !state.scrollBarState
    localStorage.setItem('scrollBars', state.scrollBarState ? 'true' : 'false')
    persistThemeSettings({'scrollBarState':state.scrollBarState})
  }

  const login = function (user) {

    // Emit event to close existing socket, needed if relogin before token expiration.
    // Listener is in ./components/socketConnection.
    let event = new Event('closeExistingSocket')
    dispatchEvent(event)
    state.auth = {loggedIn:true,...user}
    sessionStorage.setItem("loggedIn", true)
    if(!user.fourDayWeek) {state.auth.fourDayWeek = false}

    if(authenticated.value.token && authenticated.value.refreshToken) {
      startTokenRefresh()
    }

    setThemefromProfile(state.auth.darkModeState || 'dark')
    setScrollBarsfromProfile(state.auth.scrollBarState || false)

    authChannel.postMessage({type:'other-tab-login'})


    //if login triggered, db connection is true.
    state.systemStatus.dbConnection = true
    getPreReleaseAuth()


    // connect websocket, function in ./components/socketConnection.
    console.log('Connecting WebSocket')
    socketConnect(state.auth.token)
  }

  let refreshTimer = null

  const startTokenRefresh = () => {
    if (refreshTimer) {clearTimeout(refreshTimer)}

    if (authenticated.value.token && authenticated.value.refreshToken) {
        const tokenPayload = parseJwt(authenticated.value.token);

        if (!tokenPayload || !tokenPayload.exp) {
            console.error('Invalid token payload or missing expiration');
            return;
        }

        const refreshTime = (tokenPayload.exp * 1000) - Date.now() - 60000; // 1 minute before expiration
        let retryCount = 0;
        const maxRetries = 5;

        console.log('Initiating Token refresh process.');

        const attemptRefresh = async () => {
            try {
                console.log('Attempting Token Refresh.');
                const refreshResponse = await api.post('auth/refreshToken', {
                    refreshToken: authenticated.value.refreshToken,
                });

                if (
                    refreshResponse.data?.data?.token &&
                    refreshResponse.data?.data?.refreshToken
                ) {
                    state.auth.token = refreshResponse.data.data.token;
                    state.auth.refreshToken = refreshResponse.data.data.refreshToken;

                    console.log('Token successfully refreshed.');
                    startTokenRefresh(); // Restart refresh process for next expiration
                } else {
                    throw new Error('Tokens not returned in response');
                }
            } catch (error) {
                retryCount++;
                console.error(
                    `Failed to refresh token. Attempt ${retryCount}/${maxRetries}:`,
                    error.response?.data?.error || error.message || error
                );

                if (retryCount < maxRetries) {
                    console.log(
                        `Retrying token refresh in 5 minutes. Attempt ${retryCount + 1}/${maxRetries}`
                    );
                    setTimeout(attemptRefresh, 5 * 60 * 1000); // Retry after 5 minutes
                } else {
                    console.error('Max retries reached. Token refresh failed.');
                }
            }
        };

        refreshTimer = setTimeout(attemptRefresh, Math.max(refreshTime, 0)); // Schedule initial refresh attempt
    }
};

  const updateLocalAccount = (user)=>{
    if(typeof user === 'object' && authenticated.value.loggedIn) {
      state.auth = {loggedIn:true,...user}
    }
  }

  const loginAnon = (secureCode)=>{
    let event = new Event('closeExistingSocket')
    dispatchEvent(event)
    state.systemStatus.dbConnection = true
    console.log('Attempting Anonymous WebSocket Connection.')
    socketConnect(null,secureCode)
  }

  const logout = async () => {
    window.location.reload()
    authChannel.postMessage({type:'logout'})
    //state.logOutFlag = true
    //localStorage.setItem("other-tab-logout", "");
    ////localStorage.clear();
    //
    //const initialAuthState = {
    //  loggedIn: false,
    //  name: '',
    //  email: '',
    //  role: {},
    //  token: '',
    //  organization: '',
    //  timesheet: false,
    //  onSchedule: false,
    //  fourDayWeek: false
    //};
    //state.auth = {...initialAuthState}
    //sessionStorage.removeItem("loggedIn")
    //window.location.pathname = '/'
  }


  // reestablished login if the browser is refreshed
//  const authOnRefresh = async () => {
//
//    if (window.performance) {
//
//      if (performance.getEntriesByType("navigation")[0].type === "reload" &&
//        document.cookie) {
//        let decodedCookie = decodeURIComponent(document.cookie);
//        const cookieValue = decodedCookie
//          .split(";")
//          .find((row) => row.startsWith("refresh="))
//          .split("=")[1];
//        if (cookieValue) {
//          await axios.get('/api/v1/auth/me', {
//            headers: {
//              authorization: "Bearer " + cookieValue,
//            },
//          })
//            .then((response) => {
//              if (typeof response.data === 'object' && response.data._id && response.data.token) {
//                login(response.data)
//              }
//            })
//            .catch((err) => {
//              console.error(err)
//            })
//          document.cookie = `refresh=;max-age=-1;expires=${Date.now() - 10};SameSite=Lax`;
//          if (localStorage.getItem("refresh")) { localStorage.removeItem("refresh") }
//
//        }
//      } else {
//        document.cookie = `refresh=;max-age=-1;expires=${Date.now() - 10};SameSite=Lax`;
//      }
//    } else {
//      console.info("window performance api not available");
//    }
//  }

  // Sets value of server connection to DB and Browser Websocket connection to server.
  // Displayed as red/green in App.vue
  const setAppStatus = (type, status) => {
    if (type == 'db') { state.systemStatus.dbConnection = status }
    if (type == 'ws') { state.systemStatus.wsConnection = status }
  }

  const setResetPasswordFlag = () => {
    state.resetPassword = true
  }

  const toggleSideBar = () => {
    state.sideBarOpen = !state.sideBarOpen
  }

  const closeSideBar = () => {
    state.sideBarOpen = false
  }

  const updateRole = (role)=>{
    state.auth.role = role
  }

  const convertDate = async (val) =>{
    let selectedDate = new Date(val)
    let convertedDate =  new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() *60000)
    return convertedDate
  }

  const distanceBetweenPoints = (lon1,lat1,lon2,lat2,unit)=> {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit=="K") { dist = dist * 1.609344 }
      if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  const formatDate = (date)=> {
    return format(new Date(date), 'ccc MMM dd, yyyy')
  }

  const sendChangeEvent = (type,projectId,shipmentId,data)=>{
      let changeEvent = new CustomEvent("local_data_change",{detail:{type,projectId,shipmentId,data}} );
      dispatchEvent(changeEvent);
  }

  const openDetails = (target) =>{
    if(target.nextElementSibling) {
      target.nextElementSibling.classList.toggle('hidden')
    }
  }

  const setModalBlocked = (val)=>{
    state.modalBlock = val
  }

  const testJSON = (data)=> {
      if (typeof data !== "string" || data === '') {
      return false;
      }
      try {
          JSON.parse(data);
          return true;
      } catch (error) {
          return false;
      }
  }

  const setProjectListSettings = (key,value)=>{
    state.projectListSettings[key] = value
  }

  const getPreReleaseAuth = async ()=>{
    await api
    .get(`preRelease/authState`)
    .then(res=>{
      res.data?.data && typeof res.data.data === 'object' ? setPreReleaseAuth(res.data.data) : null
    })
    .catch(err=>{
      console.error(err.response?.data?.error || err.message)
    })
  }

  const setPreReleaseAuth = (value)=>{
    if(value && typeof value === 'object') {
      state.preReleaseAuthState = value
    }
  }

  const persistThemeSettings = async (body)=>{
    if(authenticated.value?._id) {
      await api
      .put('auth/updateme',body)
      .then((res)=>{
          res.data?.data && typeof res.data.data === 'object'
          ? sendChangeEvent('themeSettings','','',{userId:res.data?.data._id,...body})
          : null
        }
      )
      .catch((err)=>{
        console.error(err.response?.data?.error || err.message)
      })
    }
  }

  const parseJwt = (token)=> {
    try {
        if (!token) {
            throw new Error('Token is undefined or null');
        }

        const base64Url = token.split('.')[1];
        if (!base64Url) {
            throw new Error('Invalid JWT format');
        }

        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Failed to parse JWT:', error.message);
        return null; // Gracefully return null if parsing fails
    }
}

export default {
  state: readonly(state),
  expiration,
  authenticated,
  appStatus,
  initials,
  isLogoutError,
  resetPasswordFlag,
  sideBar,
  loggingOut,
  login,
  loginAnon,
  logout,
  //authOnRefresh,
  setAppStatus,
  setResetPasswordFlag,
  toggleSideBar,
  closeSideBar,
  convertDate,
  distanceBetweenPoints,
  formatDate,
  sendChangeEvent,
  updateRole,
  testJSON,
  openDetails,
  isModalBlocked,
  setModalBlocked,
  darkState,
  scrollState,
  setTheme,
  toggleTheme,
  setScrollBars,
  toggleScrollBars,
  projectViewSettings,
  setProjectListSettings,
  preReleaseAuth,
  setPreReleaseAuth,
  getPreReleaseAuth,
  authChannel,
  authRecieved,
  requestAuth,
  updateLocalAccount
}

