<template>
  <div class="w-screen h-screen flex flex-col app-style min-h-568 text-xs" :class="[{dark:darkMode},scrollBars ? darkMode ? 'scroll-on-dark' :'scroll-on' : 'scroll-off']" :style="'min-width:375px'">
  <!-- Logout and Token Expiration -->
    <div v-if="loggingOut" class="w-screen h-screen absolute app-style bg-neutral-100 dark:bg-neutral-900 z-100">
      <div class="w-full h-full flex items-center justify-center">
        <LoadingData :size="'large'" :message="'Logging Out.'" />
      </div>
    </div>
    <!--<TokenExpiration />-->
  <!-- Top Navbar -->
    <div v-if="authenticated.loggedIn" class="sticky top-0 w-full shadow-xl z-70 no-print">
      <div class="w-full bg-black dark:border-b h-12 flex flex-row items-center px-4 text-neutral-300">
        <div class="absolute left-3 flex items-center justify-center flex-nowrap space-x-1">
      <!-- Open\Close Sidebar -->
          <div class="cursor-pointer mr-4 text-neutral-300 hover:opacity-75" @click="toggleSideBar" :class="sideBar ? ' transform rotate-180' : ''">
            <ArrowCircleRightIcon class="h-5 w-5'" />
          </div>
      <!-- Status Monitors --> 
        <StatusAndOptionsMenu />
        </div>
      <!--Center Logo -->
        <div class="hidden md:flex md:w-56 sm:w-44 place-content-center mx-auto">
          <img src="./assets/Logo_long.png" alt="DLS" class="object-contain self-center md:w-56 sm:w-44 h-auto"/>
        </div>
        <div class="h-12 w-12 hidden sm:flex md:hidden place-content-center mx-auto">
          <img src="./assets/favicon.png" alt="DLS" class="object-contain self-center h-12 w-12"/>
        </div>
      <!-- Queues -->
        <div class="absolute right-4 z-70 flex flex-row flex-nowrap space-x-1 items-center">
        <!--Warehouse and Request Inboxes -->
          <div v-if="authenticated.organization?.orgType?.includes('primary')" class="flex flex-row items-center justify-center px-2">
            <div v-for="(queue,index) in queues" :key="index">
              <div v-if="queue.condition" class="flex justify-center">
                <Popper hover arrow placement="bottom" :content="queue.popper ? queue.popper : null">
                  <router-link :to="queue.path" replace tabindex="-1">
                    <component :is="queue.component" />
                  </router-link>
                </Popper>
              </div>
            </div>
          </div>
      <!-- Account Info -->
          <div class=" rounded-full bg-neutral-500 h-8 w-8 flex items-center justify-center font-bold text-sm hover:bg-sky-700 cursor-pointer"
            @mouseenter="showMenu"
            @mouseleave="showMenu"
            @focus="showMenu"
            @blur="showMenu"
            @click="showMenu"
          >
            <span v-if="initials">{{ initials }}</span>
            <span v-else>?</span>
        <!-- Account\Profile menu -->
            <div v-if="menu" class="absolute w-36 top-8 right-1 shadow-lg overflow-auto pt-1 z-60" @click="showMenu">
              <div class="w-full flex flex-col shadow-md rounded-b-md py-1 text-neutral-800">
                <div v-for="(item,index) in userMenu" :key="index" class="w-full">
                  <div v-if="item.condition" class="w-full bg-neutral-100 dark:bg-neutral-800 text-neutral-700 dark:text-neutral-300 p-1 whitespace-normal truncate hover:bg-sky-700 hover:text-neutral-300 cursor-pointer border-b"
                  @click="gotoRoute(item.link)"
                  >
                    {{item.title}}
                  </div>
                </div>
                  <a class="w-full bg-neutral-100 dark:bg-neutral-800 text-neutral-700 dark:text-neutral-300 p-1 whitespace-normal truncate hover:bg-sky-700 hover:text-neutral-300 cursor-pointer"
                      href="#"
                      @click="logout"
                      >Log Out
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- SideBar -->
    <div v-if="authenticated.loggedIn"  class="transform top-12 left-0 w-36 fixed h-full ease-in-out transition-all duration-300 z-70 p-1 flex flex-col pb-3 bg-neutral-100 dark:bg-neutral-900"
      :class="sideBar ? 'translate-x-0 border-r-2' : '-translate-x-full'"
      @click="toggleSideBar"
    >
      <div class="w-full flex flex-col flex-grow overflow-x-hidden overflow-y-auto">
          <div class="w-full flex-grow" @click="toggleSideBar">
            <div class="flex flex-col items-center p-2 mb-3 sticky top-0 bg-neutral-100 dark:bg-neutral-900">
              <div class=" bg-white h-20 flex place-content-center w-20 rounded-full shadow-lg">
                <img src="./assets/favicon.png" alt="DLS" class="object-contain h-16 self-center w-16" />
              </div>
            </div>
            <div v-for="item in sideBarLinks" :key="item.path" class="w-full flex flex-col items-start">
              <div v-if="item.children && item.condition" class="w-full flex flex-row items-center space-x-2 cursor-pointer whitespace-normal text-sm font-semibold flex-nowrap p-2 truncate hover:border-y" @click="item.isOpen = !item.isOpen">
                <component :is="item.icon" class="w-5 h-5 pointer-events-none" />
                <div class="text-left truncate pointer-events-none">{{item.label}}</div>
              </div>
              <router-link v-else-if="item.condition && !item.children" class="w-full flex flex-row items-center space-x-2 cursor-pointer text-sm whitespace-normal font-semibold flex-nowrap p-2 truncate hover:border-y"
                :to="item.path"
                replace
                :tabindex="!sideBar ? '-1' : null"
              >
                <component :is="item.icon" class="w-5 h-5 pointer-events-none" />
                <div class="w-full text-left truncate pointer-events-none whitespace-normal">{{item.label}}</div>
              </router-link>
              <div v-if="item.isOpen && item.children" class="w-full">
                <div v-for="child in item.children" :key="child.path" class="w-full pl-2 truncate hover:border-y">
                  <router-link 
                    v-if="child.condition"
                    :key="child.path" 
                    :to="child.path" 
                    class="flex flex-row items-center space-x-2 cursor-pointer font-semibold flex-nowrap p-2 truncate whitespace-normal" 
                    replace
                    :tabindex="!sideBar ? '-1' : null"
                  >
                    <component :is="child.icon" class="h-4 w-4 pointer-events-none flex-shrink-0" />
                    <div class="text-left truncate pointer-events-none whitespace-normal">{{ child.label }}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
    <!-- About link and icon at bottom of menu -->
          <!--<div class="flex flex-col flex-grow justify-end py-1 px-2" @click="toggleSideBar">
              <router-link class="btn-icon" to="/about" replace>
              <InformationCircleIcon class="'h-6 w-6"/>
              </router-link>
          </div>-->
      </div>
    </div>
  <!-- Main content displays router view -->
    <div id="main" class="ease-in-out transition-all duration-300 flex-grow flex flex-col" :class="authenticated.loggedIn && sideBar ? 'pl-36' : ''">
      <div v-if="authenticated.organization?.logoId && !sideBar" class="lg:flex place-content-center absolute w-16 h-16 top-14 right-2 z-30 hidden"
      :class="sideBar ? 'ease-in-out transition-all duration-300 transform top-logo-bar': 'ease-in-out transition-all duration-300 transform pl-0'">
        <div v-if="authenticated.organization?.logoId !== ''" class="w-24 flex flex-row items-center justify-center rounded-full">
          <img :src="`https://drive.google.com/thumbnail?authuser=0&size=h100&alt=media&id=${authenticated.organization.logoId}`" alt="DLS" onerror="this.onerror = null;this.src='/favicon.png';" class="object-contain align-middle border-none p-1" :style="'max-height:100px'" />
        </div>
      </div>
        <router-view :key="$route.fullPath" />
  <!-- Modals -->
      <teleport to="#modals" v-if="isLogoutError">
        <logout-modal />
      </teleport>
      <div id="modals"></div>
    </div>
  </div>
</template>

<script>
import { provide, ref,onBeforeUnmount, onMounted,computed, onBeforeMount} from "vue";
import global from "./global";
import axios from "axios";
import {useRouter} from 'vue-router'
import {
  QrcodeIcon,
  DocumentTextIcon,
  ArrowCircleRightIcon,
  HomeIcon,
  ClipboardIcon,
  TruckIcon,
  SearchCircleIcon,
  CalendarIcon,
  InformationCircleIcon,
  CurrencyDollarIcon,
  ClockIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon as DocumentsIcon,
  ServerIcon,
  BookOpenIcon,
  UsersIcon,
  OfficeBuildingIcon,
  CogIcon,
  UserGroupIcon,
  CashIcon,
  CodeIcon,
  SearchIcon,
  LibraryIcon,
  PlusCircleIcon as PlusCircleIconOutline

  } from "@heroicons/vue/outline"

import {ClipboardCheckIcon,UserGroupIcon as UserGroupSolidIcon,SparklesIcon,PlusCircleIcon} from '@heroicons/vue/solid'
//import LogisticVanIcon from "./components/customIcons/LogisticVanIcon.vue"
import VanIcon from "@/components/customIcons/VanIcon.vue"
import WrenchIcon from './components/customIcons/WrenchIcon.vue';
import PunchClock from '@/components/customIcons/PunchClock.vue'
import ExcelIcon from '@/components/customIcons/ExcelIcon.vue';
import TreeIcon from '@/components/customIcons/TreeIcon.vue';


// displays modal to close tab if problem with logout api
import StatusAndOptionsMenu from './components/ui/StatusAndOptionsMenu.vue';
import LogoutModal from "./components/LogoutModal";
//import TokenExpiration from "./components/tokenExpiration.vue";
import WarehouseQueue from "./components/warehouse/WarehouseQueue.vue"
import RackBuildQueue from './components/warehouse/rackBuilds/RackBuildQueue.vue';
import InvoiceQueue from "@/components/invoices/InvoiceQueue.vue"
import api from './api';

export default {
  components: {
    LogoutModal,
    StatusAndOptionsMenu,
    CalendarIcon,
    UserGroupIcon,
    UsersIcon,
    OfficeBuildingIcon,
    CogIcon,
    InformationCircleIcon,
    DocumentsIcon,
    WarehouseQueue,
    QrcodeIcon,
    DocumentTextIcon,
    RackBuildQueue,
    ArrowCircleRightIcon,
    HomeIcon,
    ClipboardIcon,
    TruckIcon,
    SearchCircleIcon,
    CurrencyDollarIcon,
    ClockIcon,
    CheckCircleIcon,
    //TokenExpiration,
    VanIcon,
    WrenchIcon,
    ServerIcon,
    BookOpenIcon,
    PunchClock,
    InvoiceQueue,
    CashIcon,
    ExcelIcon,
    CodeIcon,
    ClipboardCheckIcon,
    UserGroupSolidIcon,
    SparklesIcon,
    SearchIcon,
    PlusCircleIcon,
    LibraryIcon,
    PlusCircleIconOutline,
    TreeIcon
  },
  setup() {
    provide("global", global);
    const router = useRouter()
    // toggle to display profile submenu on hover
    const menu = ref(false);
    const isLoading = ref(false);

    // toggle profile menu display
    const showMenu = () => {
      menu.value = !menu.value;
    };

    // global state
    const {
      authenticated,
      appStatus,
      darkState,
      scrollState,
      setTheme,
      toggleTheme,
      setScrollBars,
      toggleScrollBars,
      initials,
      logout,
      isLogoutError,
      sideBar,
      toggleSideBar,
      loggingOut,
      updateRole,
      getPreReleaseAuth,
      preReleaseAuth,
      updateLocalAccount
    } = global;

    const queues = computed(()=>{
      return [
        {component:InvoiceQueue,popper:'Invoices',condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && authenticated.value.role?.accessModel?.invoices > 0,path:'/invoiceRequests'},
        {component:RackBuildQueue,popper:'Rack Builds',condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && authenticated.value.role?.accessModel?.rackQueue > 0,path:'/rackBuildRequests'},
        {component:WarehouseQueue,popper:'Shipments',condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && authenticated.value.role?.accessModel?.warehouseQueue > 0,path:'/shippingRequests'}
      ]
    })

    const userMenu = computed(()=>{
      return [
        {title:'My Account',link:'/myAccount',condition:true},
        {title:'Time Off',link:'/pto',condition:authenticated.value.role?.accessModel?.pto > 0},
        {title:'Time Clock',link:'/timeclock',condition:authenticated?.value?.timesheet},
        {title:'Timesheets',link:'/timecards',condition:authenticated?.value?.timesheet},
        {title:'About',link:'/about',condition:true}
      ]
    })

    const sideBarLinks = computed(()=>{
      return [
        {
          path:'/',
          icon:HomeIcon,
          label:'Home',
          condition:true
        },
        {
          path:'/projects',
          icon:ClipboardIcon,
          label:'Project List',
          condition:authenticated.value.role?.accessModel?.projects > 0
        },
        {
          path:'/newProject',
          icon:SparklesIcon,
          label:'New Project',
          condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && authenticated.value.role?.accessModel?.projects > 2
        },
        {
          path:'/projectSearch',
          icon:SearchIcon,
          label:'Proj. Search',
          condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && authenticated.value.role?.accessModel?.projects > 2
        },
        {
          path:'#',
          icon:CurrencyDollarIcon,
          label:'Invoices',
          condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && (authenticated.value.role?.accessModel?.invoices > 0 || authenticated.value.role?.accessModel?.projects > 3) ,
          isOpen:false,
          children:[
            {
              path:'/invoices',
              icon:CurrencyDollarIcon,
              label:'View Invoices',
              condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && (authenticated.value.role?.accessModel?.invoices > 0 || authenticated.value.role?.accessModel?.projects > 3) 
            },
            {
              path:'/openInvoices',
              icon:CashIcon,
              label:'Open Invoices',
              condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')) && (authenticated.value.role?.accessModel?.invoices > 0 || authenticated.value.role?.accessModel?.projects > 3)
            }
          ]
        },
        {
          path:'#',
          icon:ServerIcon,
          label:'Equipment',
          condition:(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary')),
          isOpen:false,
          children:[
            {
              path:'/equipmentCatalog',
              icon:BookOpenIcon,
              label:'Catalog',
              condition:authenticated.value.organization?.orgType?.includes('primary') && authenticated.value.role?.accessModel?.receiving > 0 
            },
            {
              path:'/findEquipment',
              icon:SearchCircleIcon,
              label:'Find',
              condition:true
            },
            {
              path:'/printLabels',
              icon:DocumentTextIcon,
              label:'Labels',
              condition:authenticated.value.organization?.orgType?.includes('primary') && (authenticated.value.role?.accessModel?.rackQueue > 0 || authenticated.value.role?.accessModel?.warehouseQueue > 0 ||  authenticated.value.role?.accessModel?.projects > 0 )
            },
          {
              path:'/receiving',
              icon:QrcodeIcon,
              label:'Receiving',
              condition:authenticated.value.organization?.orgType?.includes('primary') && authenticated.value.role?.accessModel?.receiving > 0
            },
            {
              path:'/tools',
              icon:WrenchIcon,
              label:'Tools',
              condition:authenticated.value.organization?.orgType?.includes('primary') && authenticated.value.role?.accessModel?.warehouseQueue > 0
            }
          ]
        },
        {
          path:'#',
          icon:CalendarIcon,
          label:'Scheduling',
          condition:authenticated.value.organization?.orgType?.includes('primary'),
          children:[
            {
              path:'/scheduleResources',
              icon:UserGroupIcon,
              label:'Tech',
              condition:authenticated.value.organization?.orgType?.includes('primary')
            },
            {
              path:'/shipmentSchedule',
              icon:TruckIcon,
              label:'Delivery',
              condition:authenticated.value.organization?.orgType?.includes('primary') && authenticated.value.role?.accessModel?.scheduling > 0
            },
          ]
        },
        {
          path:'/scanner',
          icon:QrcodeIcon,
          label:'Scan',
          condition:authenticated.value.organization?.orgType?.includes('primary')
        },
        {
          path:'#',
          icon:ClockIcon,
          label:'Time Mgmt.',
          condition:((authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary'))) && authenticated.value.role?.accessModel?.approveTimesheets > 0 || authenticated.value.timesheet,
          isOpen:false,
          children:[
            {
              path:'/myTimeclocks',
              icon:PunchClock,
              label:'My Timeclock',
              condition:authenticated.value.timesheet
            },
            {
              path:'/timeclockAdmin',
              icon:ClipboardCheckIcon,
              label:'Timeclock Admin',
              condition:authenticated.value.role?.accessModel?.approveTimesheets > 0
            },
            {
              path:'/approveTimecards',
              icon:CheckCircleIcon,
              label: 'Approve Timesheets',
              condition:authenticated.value.role?.accessModel?.approveTimesheets > 0,
            }
          ]
        },
        {        
          path:'#',
          icon:LibraryIcon,
          label:'HR/Payroll',
          condition:((authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary'))) && (authenticated.value.role?.accessModel?.payroll > 0 || authenticated.value.role?.accessModel?.scheduling > 0),
          isOpen:false,
          children:[
            {
              path:'/payroll',
              icon:CurrencyDollarIcon,
              label: 'Payroll',
              condition:authenticated.value.role?.accessModel?.payroll > 0,
            },
            {
              path:'/specialRates',
              icon:CurrencyDollarIcon,
              label:'Special Rates',
              condition:authenticated.value.role?.accessModel?.payroll > 0,
            },
            {
              path:'/employeePTOInfo',
              icon:UserGroupSolidIcon,
              label:'Employee Details & PTO',
              condition:authenticated.value.role.accessModel.payroll > 0
            },
            {
              path:'/reports/PTOReports',
              icon:UserGroupSolidIcon,
              label:'PTO Report',
              condition:authenticated.value.role.accessModel.payroll > 0
            },
            {
              path:'/employeeSickTime',
              icon:PlusCircleIconOutline,
              label:'Sick Day Report',
              condition:authenticated.value.role.accessModel.payroll > 0
            },
            {
              path:'/holidays',
              icon:TreeIcon,
              label:'Holidays',
              condition:authenticated.value.role.accessModel.payroll > 0 || authenticated.value.role?.accessModel?.scheduling > 0
            },
            {
              path:'/ptoPlans',
              icon:CalendarIcon,
              label:'PTO Plans',
              condition:authenticated.value.role.accessModel.payroll > 0
            },
            {
              path:'/sickTime',
              icon:PlusCircleIcon,
              label:'Sick Day Plans',
              condition:authenticated.value.role.accessModel.payroll > 0
            },
          ]
        },
        {
          path:'/vehicleManagement',
          icon:VanIcon,
          label:'Vehicles',
          condition:authenticated.value.organization?.orgType?.includes('primary') && (authenticated.value.role?.accessModel?.warehouseQueue > 0 || authenticated.value.role?.accessModel?.scheduling > 0)
        },
        {
          path:'/users',
          icon:UsersIcon,
          label:'Contacts',
          condition:authenticated.value.role?.accessModel?.manageAccounts > 0
        },
        {
          path:'/organizations',
          icon:OfficeBuildingIcon,
          label:'Companies',
          condition:authenticated.value.role?.accessModel?.organizations > 0
        },
        {
          path:'/DataManagement',
          icon:CogIcon,
          label:'App Data',
          condition:authenticated.value.organization?.orgType?.includes('primary') && authenticated.value.role?.accessModel?.appData > 0
        },
        {
          path:'#',
          icon:CodeIcon,
          label:'Pre Release',
          condition: Array.isArray(preReleaseAuth.value) && preReleaseAuth.value.length !== 0,
          isOpen:false,
          children:[
            {
              path:'/importProject',
              icon:ExcelIcon,
              label:'Import Project',
              condition:Array.isArray(preReleaseAuth.value) && preReleaseAuth.value.includes('Project Import')
            },
            {
              path:'/importTabs',
              icon:ExcelIcon,
              label:'Import Tabs',
              condition:Array.isArray(preReleaseAuth.value) && preReleaseAuth.value.includes('Tab Import')
            },
            {
              path:'/scheduleTest',
              icon:CalendarIcon,
              label:'Schedule Test',
              condition:Array.isArray(preReleaseAuth.value) && preReleaseAuth.value.includes('Schedule Test')
            },
            {
              path:'/timeclockReport',
              icon:ClockIcon,
              label:'Timeclock Report',
              condition:Array.isArray(preReleaseAuth.value) && preReleaseAuth.value.includes('Timeclock Report') && authenticated.value.role?.accessModel?.approveTimesheets > 0
            }
          ]
        },
        {
          path:'/adminTesting',
          icon:OfficeBuildingIcon,
          label:'Admin Testing',
          condition:authenticated.value.role?.level > 9
        },
      ]
    })

    const gotoRoute = (path)=>{
      router.push(path)
    }

    const darkMode = ref(darkState)
    const scrollBars = ref(scrollState)

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        console.error(err?.response?.data?.error || err?.message || err);
        throw err;
      }
    );


    onBeforeMount(()=>{
      //requestAuth()
      const savedTheme = localStorage.getItem('theme') || 'dark'
      setTheme(savedTheme)
      const savedScrollBarSetting = localStorage.getItem('scrollBars') || 'false'
      setScrollBars(savedScrollBarSetting)
    })

    onMounted(()=>{
      window.addEventListener("data_change_from_socket",handleSocketChange)
    })

    onBeforeUnmount(()=>{
      window.removeEventListener("data_change_from_socket",handleSocketChange)
    })


    window.addEventListener("pagehide", () => {
      if (window.performance && authenticated.value.token) {
        document.cookie = `refresh=${authenticated.value.token};max-age=30; SameSite=Lax`;
        const refreshKey = Math.random();
        sessionStorage.setItem("refresh", refreshKey);
        if (!localStorage.getItem("refresh")) {
          localStorage.setItem("refresh", refreshKey);
        }
      }
    });

    const updateLocalUser = async ()=>{
      await api
      .get('auth/loginUpdate')
      .then(res=>{
        if(typeof res.data?.data === 'object') {
          let newUserInfo = res.data.data
          newUserInfo.token = authenticated.value.token
          newUserInfo.refreshToken = authenticated.value.refreshToken
          updateLocalAccount(newUserInfo)
        }
      })
      .catch(err=>{
        console.error(err.response?.data?.error || err.message)
      })
    }

    const handleSocketChange = (e)=>{
      if(e.detail?.type) {
        if(e.detail.type === 'role' && authenticated.value.loggedIn) {
          if(e.detail?.data?._id === authenticated.value.role?._id ) {
            updateRole(e.detail.data)
          }
        } else if(e.detail.type === 'user' && authenticated.value.loggedIn) {
          if(e.detail?.data?.userId === authenticated.value?._id) {
            updateLocalUser()
          }
        } else if(e.detail.type === 'preRelease' && authenticated.value.loggedIn ) {
          getPreReleaseAuth()
        } else if(e.detail.type === 'logout' && e.detail?.data?.userId === authenticated.value?._id && authenticated.value.loggedIn) {
          logout()
        }
      } else {
        console.error('Bad data received from socket.')
      }
    }
     
     const openDetails = (target) =>{
      if(target) {
        target.nextElementSibling.classList.toggle('hidden')
      }
    }

    return {
      authenticated,
      appStatus,
      initials,
      logout,
      isLogoutError,
      showMenu,
      queues,
      menu,
      toggleSideBar,
      sideBar,
      isLoading,
      loggingOut,
      openDetails,
      sideBarLinks,
      userMenu,
      gotoRoute,
      darkMode,
      scrollBars,
      toggleTheme,
      setScrollBars,
      toggleScrollBars,
    };
  },
};
</script>

<style>
  #app {
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding:0;
    margin:0;
  }
</style>
